import React, { useRef } from 'react';

import CallToAction from './components/callToAction.jsx';

import ButtonPrimary from './components/buttons/buttonPrimary.jsx';
import H1 from './components/headings/h1.jsx'
import H2 from './components/headings/h2.jsx'
import H3 from './components/headings/h3.jsx'
import Description from './components/texts/description.jsx'
import Body from './components/texts/body.jsx'
import useScrollToRefSection from './hooks/useScrollToRefSection.jsx';
import Check from './components/icons/check.jsx';
import Minus from './components/icons/minus.jsx';
import XIcon from './components/icons/xIcon.jsx';

export default function Developers() {

  const sectionRefs = {
    sftp: useRef()
  }

  useScrollToRefSection(sectionRefs);

  return <>
    <div className='container mx-auto px-4 md:px-0 mt-16 md:mt-24'>
      <div id='api' className='mb-16 md:mb-24 md:w-2/3'>
        <H1>Developer-centric</H1>

        <Description>Integrate digital offers in your loyalty platform and other applications with the BYBE API. Utilizing our platform saves you time and energy without the hassle of jumping hoops through regulation.</Description>

        <ButtonPrimary url={'https://docs.bybe.io/'} gaEventCategory={'developers-page-click'} gaEventAction={'click-to-view-api-docs'} newWindow text="View API documentation" mobileCenter _blank />
      </div>
    </div>

    <div className='bg-[#f3f4f6]'>
      <div className='container mx-auto px-4 md:px-0 pt-16 md:pt-24'>
        <H2>Getting offers</H2>
        <Description twoThirdsDesktop>Pull live digital rebate offers directly to your app, allowing you to customize the user experience.</Description>
        <div className='mb-16 md:mb-24 xl:w-3/4'>
          <img src='../images/screenshots/bybe-api/1-get-offer.jpg'></img>
        </div>

        <H2>Clipping offers</H2>
        <Description twoThirdsDesktop>When offers are saved in your app or website, we record the data within our dashboard for automatic tracking.</Description>
        <div className='mb-16 md:mb-24 xl:w-3/4'>
          <img src='../images/screenshots/bybe-api/2-clip.jpg'></img>
        </div>

        <H2>Sending transactions</H2>
        <Description twoThirdsDesktop>Whether the product is purchased online, in-store, or drive-up/pickup, share the transactions through our API and we handle the rest.</Description>
        <div className='mb-16 md:mb-24 xl:w-3/4'>
          <img src='../images/screenshots/bybe-api/3-post-to-rd.jpg'></img>
        </div>

        <H2>Delivering redemptions</H2>
        <Description twoThirdsDesktop>Once we receive transactions, we validate the transaction against offer requirements. Then we send an email to the consumer enabling them to cash out via Paypal, Venmo, or a Visa prepcaid card. </Description>
        <div className='mb-16 md:mb-24 xl:w-3/4'>
          <img src='../images/screenshots/bybe-api/4-payout-flow.jpg'></img>
        </div>

        <div className='pb-16 md:pb-24'>
          <ButtonPrimary url={'https://docs.bybe.io/'} gaEventCategory={'developers-page-click'} gaEventAction={'click-to-view-api-docs'} newWindow text="View API documentation" center _blank />
        </div>

      </div>
    </div>

    <div className='container mx-auto px-4 md:px-0 mt-16 md:mt-24'>
      <div ref={sectionRefs['sftp']} className='mb-16 md:mb-24'>
        <H1>SFTP integration</H1>
        <Description> If you're unable to use the real-time API for sending transactions, you can easily upload post-purchase data to us via SFTP.</Description>
        <H2>Requirements</H2>
        <Body>SFTP Hostname: Unique per remote client <br />
          SFTP Port: 22 <br />
          Authentication Options: <br />
          <ul style={{ "listStyleType": "disc", "marginLeft": "1.5rem" }}>
            <li>SSH Key provided to BYBE (preferred)</li>
            <li>Username/Password provided by BYBE</li>
          </ul>

          File format: CSV
        </Body>

        <H2>SFTP Integration for BYBE API (Retail Partner’s Loyalty Programs)

</H2>
        <H3>Redemptions</H3>
        <Body>
          Redemptions to be sent at least once per day using write-enabled SFTP account<br />
          - SFTP filename: /redemptions/redemptions_[timestamp].csv<br /><br />
          *Timestamp should be in ISO 8601 format when the file was created<br />
        </Body>
        <H3>CSV field mapping</H3>
        <Description>Each line item has an individual row.</Description>
        <div className="max-w-full overflow-x-auto">
          <div className="grid grid-cols-5 gap-0 divide-y min-w-[890px]">
            <div className='p-3 bg-black text-white col-span-1'>Field</div>
            <div className='p-3 bg-black text-white col-span-1'>Required</div>
            <div className='p-3 bg-black text-white col-span-1'>Type</div>
            <div className='p-3 bg-black text-white col-span-2'>Description</div>

            <div className='p-3 col-span-1 text-nowrap'>purchase_date</div>
            <div className='p-3 col-span-1'><Check color='green' /></div>
            <div className='p-3 col-span-1 text-nowrap'>timestamp</div>
            <div className='p-3 col-span-2'>When the purchase occurred. Format: ISO 8601.</div>

            <div className='p-3 col-span-1 text-nowrap'>purchased_product_id</div>
            <div className='p-3 col-span-1'><Check color='green' /></div>
            <div className='p-3 col-span-1 text-nowrap'>string</div>
            <div className='p-3 col-span-2'>The product identifier (UPC) included in the purchase, associated with the transaction_id.</div>

            <div className='p-3 col-span-1 text-nowrap'>consumer_id</div>
            <div className='p-3 col-span-1'><Check color='green' /></div>
            <div className='p-3 col-span-1 text-nowrap'>string</div>
            <div className='p-3 col-span-2'>The consumer identifier (loyalty ID) for this redemption. This cannot be changed without the consumer losing their accumulated balance. Must match the consumer ID provided by Clips.</div>

            <div className='p-3 col-span-1 text-nowrap'>consumer_email</div>
            <div className='p-3 col-span-1'>
              <Minus color='gray' />
              <sup style={{ 'fontSize': '110%' }}>*</sup>
            </div>
            <div className='p-3 col-span-1 text-nowrap'>string</div>
            <div className='p-3 col-span-2'>Email to which the payout email will be sent. Does not need to be unique across consumers.</div>

            <div className='p-3 col-span-1 text-nowrap'>store_identifier</div>
            <div className='p-3 col-span-1'><Check color='green' /></div>
            <div className='p-3 col-span-1 text-nowrap'>string</div>
            <div className='p-3 col-span-2'>The store where the offer was redeemed. Must match the IDs from your store list provided to BYBE.</div>

            <div className='p-3 col-span-1 text-nowrap'>transaction_id</div>
            <div className='p-3 col-span-1'><Check color='green' /></div>
            <div className='p-3 col-span-1 text-nowrap'>string</div>
            <div className='p-3 col-span-2'>Unique transaction or receipt identifier. Must be unique for all retailer transactions. If they are not unique in your system, then you can combine fields to make them unique. An example of this is combining the transaction_id with the purchase_date.</div>

            <div className='p-3 col-span-1 text-nowrap'>units</div>
            <div className='p-3 col-span-1'><Check color='green' /></div>
            <div className='p-3 col-span-1 text-nowrap'>integer</div>
            <div className='p-3 col-span-2'>Quantity of purchased_product_id purchased.</div>

            <div className='p-3 col-span-1 text-nowrap'>price_cents</div>
            <div className='p-3 col-span-1'><Minus color='gray' /></div>
            <div className='p-3 col-span-1 text-nowrap'>integer</div>
            <div className='p-3 col-span-2'>Price of purchased_product_id in USD cents.</div>

            <div className='p-3 col-span-1 text-nowrap'>details</div>
            <div className='p-3 col-span-1'><Minus color='gray' /></div>
            <div className='p-3 col-span-1 text-nowrap'>string</div>
            <div className='p-3 col-span-2'>Product name of purchased_product_id.</div>
          </div>

        </div>

      </div>
      <div className='mb-16 md:mb-24'>
        *Consumer email is only not required if the consumers email is coming with clip data
      </div>
    </div>

    <CallToAction title={'Interested in becoming a partner?'}
      primaryButtonText={"Connect"}
      primaryButtonURL={'mailto:BYBE.DEMO@bybeapp.com'}
    />
  </>;
}